<template>
    <div class="login">
    <van-overlay :show="showOverlay">
      <div class="wrapper">
        <van-loading size="24px" color="#ffffff" vertical
          >正在授权登陆...</van-loading
        >
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Overlay, Loading,Toast } from "vant";
import { getUserInfoByPhone } from "@/api/login.js";
import { decrypt } from "@/common/utils";
export default {
  name: "login",
  components: {
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
  },
  data() {
    return {
      showOverlay: true,
      userInfo: {},
    };
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    ...mapActions(["setUserInfo"]),
    getUserInfo(){
      hw.getUserByTicketAES({
      abilityCode: "b6840fdacae2486ab864cae98031e2e6",
      organCode: "b32ae9162fff4e46afa166856ff5a785",
      success: (res) => {
        console.log(res, '成功的回调')
        const userdetail = JSON.parse(decrypt(
          res.userdetail,
          "gOuWYBjME1cVtc5S"
        ))
        getUserInfoByPhone(userdetail.name,userdetail.mobile).then(user => {
          // console.log(user)
          this.showOverlay = false
          this.setUserInfo(user)
          this.$router.replace("/");
        })
      },
      fail: (err) => {
        console.log(err, '失败的回调')
        this.showOverlay = false
        Toast(err.msg)
      }
    })
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}</style>